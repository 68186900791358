<template>
	<vx-card title="Journal Monitoring">
		<div class="vx-row mb-6" style="width:50%">
			<div class="vx-col sm:w-1/4 w-full flex items-center">
				<span>Operating Unit</span>
			</div>
			<div class="vx-col sm:w-3/4 w-full">
				<multiselect v-model="operatingUnit.selected" :options="operatingUnit.options" :multiple="false"
					:allow-empty="false" :max-height="160" placeholder=" Type to search" track-by="ID" label="Name">
					<template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title"
                        >({{ props.option.Code }}), ({{
                          props.option.OperatingUnitTerritory.Territory.code
                        }})
                        {{
                          props.option.OperatingUnitTerritory.Territory.name
                        }}</span
                      >
                    </span>
                  </template>

                  <template slot="option" slot-scope="props">
                    <div class="option__desc">
                      <span class="option__title"
                        >({{ props.option.Code }}), ({{
                          props.option.OperatingUnitTerritory.Territory.code
                        }})
                        {{
                          props.option.OperatingUnitTerritory.Territory.name
                        }}</span
                      >
                    </div>
                  </template>
				</multiselect>
			</div>
		</div>
		<div class="vx-row mb-6" style="width:50%">
			<div class="vx-col sm:w-1/4 w-full flex items-center">
				<span>Journal Type</span>
			</div>
			<div class="vx-col sm:w-3/4 w-full">
				<multiselect v-model="journalType.selected" :options="journalType.options" :multiple="false"
					:allow-empty="false" :max-height="160" placeholder=" Type to search" label="name">
					<template slot="singleLabel" slot-scope="dt">
						<span class="option__desc">
							<span class="option__title">{{ dt.option.Name }}</span>
						</span>
					</template>

					<template slot="option" slot-scope="dt">
						<div class="option__desc">
							<span class="option__title">{{ dt.option.Name }}</span>
						</div>
					</template>
				</multiselect>
			</div>
		</div>
		<div class="vx-row mb-6" style="width:50%">
			<div class="vx-col sm:w-1/4 w-full flex items-center">
				<span>Posting Date</span>
				<feather-icon title="Reset" icon="RefreshCwIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
					class="ml-2" @click.stop="resetDateFilter('posting')" />
			</div>
			<div class="vx-col sm:w-3/4 w-full">
				<date-range-picker style="min-height: 40px" class="w-full" ref="picker" opens="center"
					:locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" :singleDatePicker="false" :timePicker="false"
					:timePicker24Hour="false" :showWeekNumbers="false" :showDropdowns="false" :autoApply="true"
					v-model="filterPostingDate" :linkedCalendars="true">
					<template v-slot:input="picker">
						{{ datePickerDateFormat(picker.startDate) }} -
						{{ datePickerDateFormat(picker.endDate) }}
					</template>
				</date-range-picker>
			</div>
		</div>
		<div class="vx-col md:w-1/1 w-full">
			<vx-card>
				<vs-tabs :color="colorx">
					<vs-tab @click="colorx = 'success'" label="Not Generated Journal">
						<div class="con-tab-ejemplo">
							<notGenerateJournal :operatingUnit="operatingUnit" :journalType="journalType"
								:postingDate="filterPostingDate"></notGenerateJournal>
						</div>
					</vs-tab>
					<vs-tab @click="colorx = 'success'" label="Comparing Journal">
						<div class="con-tab-ejemplo">
							<comparingJournal :operatingUnit="operatingUnit" :journalType="journalType"
								:postingDate="filterPostingDate"></comparingJournal>
						</div>
					</vs-tab>
					<vs-tab @click="colorx = 'success'" label="Transaction VS Journal">
						<div class="con-tab-ejemplo">
							<transactionvsJournal :operatingUnit="operatingUnit" :journalType="journalType"
								:postingDate="filterPostingDate"></transactionvsJournal>
						</div>
					</vs-tab>
					<!-- <vs-tab @click="colorx = 'success'" label="Double Journal">
						<doubleJournal :operatingUnit="operatingUnit" :journalType="journalType"
							:postingDate="filterPostingDate"></doubleJournal>
					</vs-tab>
					<vs-tab @click="colorx = 'success'" label="Generated Not Found in Odoo">
						<generateNotFoundOddo :operatingUnit="operatingUnit" :journalType="journalType"
							:postingDate="filterPostingDate"></generateNotFoundOddo>
					</vs-tab> -->
				</vs-tabs>
			</vx-card>
		</div>
	</vx-card>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import notGenerateJournal from "./not-generate-journal.vue";
import doubleJournal from "./double-journal.vue";
import generateNotFoundOddo from "./generated-not-found-oddo.vue";
import comparingJournal from "./comparing-journal.vue";
import transactionvsJournal from "./transaction-vs-journal.vue";

export default {
	components: {
		DateRangePicker,
		'notGenerateJournal': notGenerateJournal,
		'doubleJournal': doubleJournal,
		'generateNotFoundOddo': generateNotFoundOddo,
		'comparingJournal' : comparingJournal,
		'transactionvsJournal' : transactionvsJournal,
	},
	data: () => ({
		colorx: "success",
		operatingUnit: {
			selected: null,
			options: [],
		},
		journalType: {
			selected: null,
			options: [],
		},
		filterPostingDate: {
			startDate: null,
			endDate: null,
		},
	}),
	watch: {
		"journalType.selected"() {
			console.log('ayyyye')
		},
	},
	mounted() {
		this.getOperatingUnit();
		const today = new Date();
		this.filterPostingDate.startDate = new Date(today.getFullYear(), today.getMonth(), 1);
		this.filterPostingDate.endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		this.getOptionJournalType();
	},
	methods: {
		getOperatingUnit() {
			this.$vs.loading();
			this.$http
				.get("/api/v1/master/operating-unit", {
					params: {
						order: "name",
						sort: "asc",
					},
				})
				.then((resp) => {
					if (resp.status == "success") {
						var options = [];
						options.push({
							ID: 0,
							Name: "All",
							OperatingUnitTerritory:{
								ID:0,
								Territory: {
									ID: 0,
									code: "-",
									name: "All"
								} 
							}
						});
						resp.data.records.forEach(function (item) {
							options.push(item);
						}, options);
						this.operatingUnit.options = options;
						this.operatingUnit.selected = this.operatingUnit.options[0];
						this.$vs.loading.close();
					} else {
						this.$vs.loading.close();
					}
				});
		},
		getOptionJournalType() {
			this.$vs.loading();
			this.$http
				.get("/api/v1/report/monitoring-journal/option")
				.then((resp) => {
					if (resp.status == "success") {
						var options = [];
						options.push({
							ID: 0,
							Name: "All"
						});
						resp.data.journals.forEach(function (item) {
							options.push(item);
						}, options);
						this.journalType.options = options;
						this.journalType.selected = this.journalType.options[0];
						this.$vs.loading.close();
					} else {
						this.$vs.loading.close();
					}
				});
		},
		datePickerDateFormat(date) {
			let a = null;
			if (date != null) {
				a = moment(date).format("dddd, MMMM Do YYYY");
			}
			return a;
		},
		resetDateFilter(name) {
			if (name == "date") {
				this.filterDate.startDate = null;
				this.filterDate.endDate = null;
			} else if (name == "posting") {
				this.filterPostingDate.startDate = null;
				this.filterPostingDate.endDate = null;
			} else if (name == "billing") {
				this.filterBillingDate.startDate = null;
				this.filterBillingDate.endDate = null;
			}
		},
	},
};
</script>
 
<style scoped>
.vs-collapse-item--content {
	height: 100%;
	max-height: 14444px;
}

.vs-dialog {
  max-width: 1000px;
}
</style>